/**
 * 功能运营统计
 */
import React, { useState } from 'react';
import { Row, Col, Card, Timeline, Table, Button, DatePicker } from 'antd';
import BreadcrumbCustom from '../widget/BreadcrumbCustom';
import { GetChartUseData, ExportStatsData } from '../../api/stats';
import { GetThemeSaveExportPU, DownThemeSaveExportPU } from '../../api/data';
import { downloadFile } from '../../utils/common';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';

const { RangePicker } = DatePicker;

class FunOperation extends React.Component {
    state = {
        selectOptions: [
            {
                label: '',
                value: '',
            },
        ],
        columns: [
            {
                title: '名称',
                dataIndex: 'themeName',
                key: 'themeName',
                width: 300,
                render: (text: any) => <span>{text}</span>,
            },
            {
                title: '主要配色',
                dataIndex: 'colors',
                key: 'colors',
                width: 200,
                render: (value: any) => (
                    <div className="theme-color">
                        {value &&
                            value.map((item: any) => {
                                return (
                                    <span
                                        key={item}
                                        className="theme-color-item"
                                        style={{ backgroundColor: item }}
                                    ></span>
                                );
                            })}
                    </div>
                ),
            },
            {
                title: '保存PV',
                dataIndex: 'save',
                key: 'save',
            },
            {
                title: '导出PV',
                dataIndex: 'export',
                key: 'export',
            },
        ],
        saveTableData: [],
        exportTableData: [],
        transmitTableData: [],
        userTableData: [],
        startTime: '',
        endTime: '',
        totalUser: 0,
        newUser: 0,
        visible: false,
        setIsModalOpen: false,
        selectedList: [],
        selectedRows: [],
        expirationTime: moment().add(1, 'days').format('YYYY-MM-DD HH:MM:SS'),
        title: '主题颜色保存/导出PV统计(2024-09-20 ~ 目前)',
    };
    // 获取图表使用统计数据
    // async getChartUseData() {
    //     const { code, data } = await GetExpertSaveExportPU();
    //     if (code === 200) {
    //         return data;
    //     }
    // }

    // 获取主题颜色保存/导出PV统计数据
    async getExpertSaveExportPU() {
        console.log('output->', 22233);
        try {
            const { code, data } = await GetThemeSaveExportPU();
            if (code == 200) {
                console.log('output->', data);
                this.setState({
                    saveTableData: data,
                    // exportTableData: this.handleChartUseData(data, 1),
                });
            }
        } catch (error) {
            console.log('output->', error);
        }
    }

    // 导出用户操作记录数据
    async exportStatsData() {
        const res = await DownThemeSaveExportPU();
        downloadFile(res, this.state.title, '.xlsx');
    }

    handleDateSelect = (date: any, dateString: any) => {
        // console.log(dateString);
        this.setState({
            startTime: dateString[0],
            endTime: dateString[1],
        });
    };
    componentDidMount() {
        this.getExpertSaveExportPU();
    }
    handleOk = () => {};
    // handleSelectChange = () => {

    // }

    render() {
        return (
            <div className="gutter-example button-demo">
                {/* <BreadcrumbCustom /> */}
                <Row gutter={16}>
                    <Col className="gutter-row" md={24}>
                        {/* <Card>
              <div>
                <span style={{ marginRight: '10px' }}>日期：</span>
                <RangePicker
                  onChange={this.handleDateSelect}
                  locale={locale}
                  ranges={{
                    今天: [moment(), moment()],
                    本月: [moment().startOf('month'), moment().endOf('month')],
                    本周: [moment().startOf('week'), moment().endOf('week')],
                  }}
                />
                <Button
                  style={{ marginLeft: '20px' }}
                  onClick={() => this.searchAllByDate()}
                >
                  查询
                </Button>
              </div>
            </Card> */}
                    </Col>
                    <Col className="gutter-row" md={24}>
                        <div className="gutter-box">
                            <Card
                                title={this.state.title}
                                extra={
                                    <Button type="primary" onClick={() => this.exportStatsData()}>
                                        导出
                                    </Button>
                                }
                                bordered={false}
                            >
                                <Table
                                    tableLayout="auto"
                                    pagination={false}
                                    scroll={{ x: 1200 }}
                                    columns={this.state.columns}
                                    dataSource={this.state.saveTableData}
                                />
                            </Card>
                        </div>
                    </Col>
                    {/* <Col className="gutter-row" md={24}>
            <div className="gutter-box">
              <Card
                title="主题颜色导出统计"
                extra={
                  <Button
                    type="primary"
                    onClick={() =>
                      this.exportStatsData(2, 0, '用户导出统计数据')
                    }
                  >
                    导出
                  </Button>
                }
                bordered={false}
              >
                <Table
                  tableLayout="auto"
                  pagination={false}
                  scroll={{ x: 1200 }}
                  columns={this.state.columns}
                  dataSource={this.state.exportTableData}
                />
              </Card>
            </div>
          </Col> */}
                </Row>
            </div>
        );
    }
}

export default FunOperation;
