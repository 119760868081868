/**
 * Created by hao.cheng on 2017/4/16.
 */
import React, { useEffect } from 'react';
import { Button, Form, Input, message } from 'antd';
import { PwaInstaller } from '../widget';
import { useAlita } from 'redux-alita';
import { RouteComponentProps } from 'react-router';
import { FormProps } from 'antd/lib/form';
import umbrella from 'umbrella-storage';
import { GithubOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { useUpdateEffect } from 'ahooks';
import { ToLogin } from '../../api/common';

const FormItem = Form.Item;
type LoginProps = {
    setAlitaState: (param: any) => void;
    auth: any;
} & RouteComponentProps &
    FormProps;

const Login = (props: LoginProps) => {
    const { history } = props;
    // const [auth, setAlita] = useAlita({ auth: {} }, { light: true });
    let auth = '';
    // useEffect(() => {
    //     setAlita('auth', null);
    // }, [setAlita]);

    // useUpdateEffect(() => {
    //     console.log(auth,"authauthauth")
    //     const sAuth =  umbrella.getLocalStorage('auth')
    //     if (auth) {
    //         // 判断是否登陆
    //         umbrella.setLocalStorage('user', auth);
    //         history.push('/');
    //     }
    // }, [history, auth]);

    const handleSubmit = async (values: any) => {
        const params = {
            username: values.userName,
            password: values.password,
        };
        const { code, token, msg } = await ToLogin(params);
        console.log('output->', token);
        if (code == 200 && token) {
            console.log('output->', token);
            message.success('登录成功');
            umbrella.setLocalStorage('tokenKey', token);
            console.log(values);
            // setAlita({ funcName: values.userName, stateName: 'auth' });
            umbrella.setLocalStorage('auth', values.userName);
            umbrella.setLocalStorage('user', values.userName);
            auth = values.userName;
            history.push('/');
        } else {
            // message.warning('用户名或密码错误！');
            console.log('error->', msg);
        }
        // if (await checkUser(values)) {}
    };
    const checkUser = async (values: any) => {
        const users = [['admin', '123456']];
        const params = {
            username: values.userName,
            password: values.password,
        };
        const { code, data } = await ToLogin(params);
        console.log('output->', data);
        if (code == 200) {
            console.log('output->', data);
        }
        return false;
        // return users.some((user) => user[0] === values.userName && user[1] === values.password);
    };

    return (
        <div className="login">
            <div className="login-form">
                <div className="login-logo">
                    <span>TOPCHARTS后台管理系统</span>
                </div>
                <Form onFinish={handleSubmit} style={{ maxWidth: '300px', width: '280px' }}>
                    <FormItem
                        name="userName"
                        rules={[{ required: true, message: '请输入用户名!' }]}
                    >
                        <Input
                            style={{ height: '40px' }}
                            prefix={<UserOutlined size={13} />}
                            placeholder="请输入用户名"
                        />
                    </FormItem>
                    <FormItem name="password" rules={[{ required: true, message: '请输入密码!' }]}>
                        <Input
                            prefix={<LockOutlined size={13} />}
                            style={{ height: '40px' }}
                            type="password"
                            placeholder="请输入密码"
                        />
                    </FormItem>
                    <FormItem>
                        {/* <span className="login-form-forgot" style={{ float: 'right' }}>
                            忘记密码
                        </span> */}
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                            style={{ width: '100%', height: '40px', marginTop: '10px' }}
                        >
                            登录
                        </Button>
                        {/* <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>或 现在就去注册!</span>
                        </p> */}
                    </FormItem>
                </Form>
            </div>
        </div>
    );
};

export default Login;
