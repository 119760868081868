import httpAjax from '../utils/httpAjax';

/**
 * 获取消费订单
 */
export const GetOrdernInfos = () => {
    return httpAjax({
        url: `/chart/orders/queryOrder`,
        method: 'get',
    });
};
/**
 * 获取智点消耗订单
 */
export const GetPotinOrder = (params: any) => {
    return httpAjax({
        url: `/user/coinLog/queryAction`,
        method: 'post',
        data: params,
    });
};

/**
 * 导出消费订单
 */
export const ExportOrderInfos = (data: any) => {
    return httpAjax({
        url: `/chart/orders/queryOrder/exportExcel`,
        method: 'get',
        responseType: 'blob', //必须要配置blob
        data,
    });
};
/**
 * 导出智点订单
 */
export const ExportPointInfos = (data: any) => {
    return httpAjax({
        url: `/user/coinLog/queryAction/exportExcel`,
        method: 'post',
        responseType: 'blob', //必须要配置blob
        data,
    });
};