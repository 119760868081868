/*
 * File: Copyright.tsx
 * Desc: 版权信息
 */
import React from 'react';

const Copyright = () => {
    return <div>TOPCHARTS后台管理系统 ©{new Date().getFullYear()}</div>;
};

export default Copyright;
