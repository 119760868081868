/**
 * 用户数据统计
 */
import React, { useState } from 'react';
import { Row, Col, Card, Timeline, Table, Button, DatePicker } from 'antd';
import BreadcrumbCustom from '../widget/BreadcrumbCustom';
import { GetAIColorData, ExpertAIColorData } from '../../api/stats';
import { downloadFile } from '../../utils/common';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';

const { RangePicker } = DatePicker;

class AiColor extends React.Component {
    state = {
        selectOptions: [
            {
                label: '',
                value: '',
            },
        ],
        columns: [],
        staColumns: [
            {
                title: '时间',
                dataIndex: 'previousDay',
            },
            {
                title: 'PV',
                dataIndex: 'pv',
            },
            {
                title: 'UV',
                dataIndex: 'uv',
            },
        ],
        transmitTableData: [],
        clickHelpTableData: [],
        userTableData: [],
        startTime: '',
        endTime: '',
        totalUser: 0,
        newUser: 0,
        visible: false,
        setIsModalOpen: false,
        selectedList: [],
        selectedRows: [],
        expirationTime: moment().add(1, 'days').format('YYYY-MM-DD HH:MM:SS'),
    };
    // 获取已转发操作的统计数据
    async getStaData() {
        const params = {
            startTime: this.state.startTime,
            endTime: this.state.endTime,        };
        const { code, data } = await GetAIColorData(params);
        if (code == 200) {
            this.setState({
                transmitTableData: data
            });
        }

    }
    // 导出
    async exportStatsData() {
        const { startTime, endTime } = this.state;
        const params = {
            startTime,
            endTime,
        };
        let fileName = "ai问色统计数据"
        const res = await ExpertAIColorData(params);
        fileName = `${startTime}~${endTime}${fileName}`;
        downloadFile(res, fileName, '.xlsx');
    }

    handleDateSelect = (date: any, dateString: any) => {
        this.setState({
            startTime: dateString[0],
            endTime: dateString[1],
        });
    };

    setSelectionType = (selectedRowKeys: any, selectedRows: any) => {
        let list: { label: any; value: any }[] = [];
        let _selectedList: any[] = [];
        selectedRows.forEach((item: { nickName: any; userId: any }) => {
            list.push({
                label: item.nickName,
                value: item.userId,
            });
            _selectedList.push(item.userId);
        });
        this.setState({
            selectOptions: list,
            selectedList: _selectedList,
            selectedRows: selectedRowKeys,
        });
    };
    async componentDidMount() {
        this.getStaData();
    }
    handleOk = () => { };

    render() {
        return (
            <div className="gutter-example button-demo">
                {/* <BreadcrumbCustom /> */}
                <Row gutter={16}>
                    <Col className="gutter-row" md={24}>
                        <Card>
                            <div>
                                <span style={{ marginRight: '10px' }}>日期：</span>
                                <RangePicker
                                    onChange={this.handleDateSelect}
                                    locale={locale}
                                    ranges={{
                                        今天: [moment(), moment()],
                                        本月: [moment().startOf('month'), moment().endOf('month')],
                                        本周: [moment().startOf('week'), moment().endOf('week')],
                                    }}
                                />
                                <Button
                                    style={{ marginLeft: '20px' }}
                                    onClick={() => this.getStaData()}
                                >
                                    查询
                                </Button>
                            </div>
                        </Card>
                    </Col>
                    <Col className="gutter-row" md={24}>
                        <div className="gutter-box">
                            <Card
                                title="AI问色统计数据(本功能自2024-11-11上线)"
                                extra={
                                    <Button
                                        type="primary"
                                        onClick={() =>
                                            this.exportStatsData()
                                        }
                                    >
                                        导出
                                    </Button>
                                }
                                bordered={false}
                            >
                                <Table
                                    tableLayout="auto"
                                    pagination={false}
                                    columns={this.state.staColumns}
                                    dataSource={this.state.transmitTableData}
                                />
                            </Card>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default AiColor;
