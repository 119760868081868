import { Interface } from 'readline';
import httpAjax from '../utils/httpAjax';

/**
 * 获取基础制图（保存、导出）统计数据
 */
export const GetChartUseData = (data: any) => {
    return httpAjax({
        url: `/statistics/getPU`,
        method: 'post',
        data,
    });
};
/**
 * 获取图表点击统计数据
 */
export const GetChartClickData = (data: any) => {
    return httpAjax({
        url: `/chart/click/queryPU`,
        method: 'post',
        data,
    });
};

/**
 * 导出基础制图统计数据
 */
export const ExportStatsData = (data: any) => {
    return httpAjax({
        url: `/statistics/exportPU`,
        method: 'post',
        responseType: 'blob', //必须要配置blob
        data,
    });
};
/**
 * 导出图表点击统计数据
 */
export const ExportStatsClickData = (data: any) => {
    return httpAjax({
        url: `/chart/click/queryPU/export`,
        method: 'post',
        responseType: 'blob', //必须要配置blob
        data,
    });
};

/**
 * 获取用户来源列表
 */
export const GetUserSource = () => {
    return httpAjax({
        // url: `/statistics/getSource`,
        url: `/registration/channel/query/list`,
        method: 'get',
    });
};

/**
 * 获取用户信息统计数据
 */
export const GetUserInfoData = (data: any) => {
    return httpAjax({
        url: `/statistics/list`,
        // url: `/statistics/user/getList`,
        method: 'post',
        data,
    });
};
/**
 * 导出用户信息统计数据
 */
export const ExportUserInfoData = (data: any) => {
    return httpAjax({
        // url: `/statistics/user/export`,
        url: `/statistics/exportUser`,
        method: 'post',
        responseType: 'blob', //必须要配置blob
        data,
    });
};
/**
 * 批量新增vip用户
 */
export const AddUserToVip = (data: any) => {
    return httpAjax({
        url: `/statistics/addVips`,
        method: 'post',
        data,
    });
};
/**
 * 删除用户
 */
export const DelUser = (params: any) => {
    return httpAjax({
        url: `/system/user/delUser`,
        method: 'get',
        params,
    });
};

/**
 * 获取AI问色统计数据
 */
export const GetAIColorData = (data: any) => {
    return httpAjax({
        url: `/user/coinLog/queryAiColorPU`,
        method: 'post',
        data,
    });
};
/**
 * 导出AI问色统计数据
 */
export const ExpertAIColorData = (data: any) => {
    return httpAjax({
        url: `/user/coinLog/queryAiColorPUExport`,
        method: 'post',
        responseType: 'blob', //必须要配置blob
        data,
    });
};