/**
 * 用户数据统计
 */
import React, { useState } from 'react';
import { Row, Col, Card, Timeline, Table, Button, DatePicker } from 'antd';
import BreadcrumbCustom from '../widget/BreadcrumbCustom';
import { GetChartUseData, ExportStatsData } from '../../api/stats';
import { GetClickHelpPU, DownClickHelpPU } from '../../api/data';
import { downloadFile } from '../../utils/common';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';

const { RangePicker } = DatePicker;

class UserOperation extends React.Component {
    state = {
        selectOptions: [
            {
                label: '',
                value: '',
            },
        ],
        columns: [],
        transmitColumns: [
            {
                title: '类型',
                dataIndex: 'type',
            },
            {
                title: '转发点击',
                dataIndex: 'click',
            },
        ],
        clickHelpColumns: [
            {
                title: '类型',
                dataIndex: 'type',
            },
            {
                title: '点击使用说明',
                dataIndex: 'click',
            },
        ],

        saveTableData: [],
        exportTableData: [],
        transmitTableData: [],
        clickHelpTableData: [],
        userTableData: [],
        startTime: '',
        endTime: '',
        totalUser: 0,
        newUser: 0,
        visible: false,
        setIsModalOpen: false,
        selectedList: [],
        selectedRows: [],
        expirationTime: moment().add(1, 'days').format('YYYY-MM-DD HH:MM:SS'),
    };
    // 获取图表使用统计数据
    async getChartUseData(params: any) {
        const { code, data } = await GetChartUseData(params);
        if (code === 200) {
            return data;
        }
    }
    // 获取表格表头数据(转发)
    getClickTableColumns(data: any) {
        const newColumns: any = [
            {
                title: '类型',
                dataIndex: 'type',
                fixed: 'left',
                width: 70,
            },
        ];
        console.log('output->',data);
        data.forEach((item: { name: any; configId: any }) => {
            const columnsItem = {
                title: item.name,
                dataIndex: `chart${item.configId}`,
                width: 100,
            };
            newColumns.push(columnsItem);
        });
        return newColumns;
    }
    handleChartUseData(data: any) {
        console.log('output->', data);
        let PV = {
            type: 'PV',
        };
        let UV = {
            type: 'UV',
        };
        data.forEach((item: { configId: any; pv: any; uv: any }) => {
            (PV as any)[`chart${item.configId}`] = item.pv;
            (UV as any)[`chart${item.configId}`] = item.uv;
        });
        let _saveData = [PV, UV];
        return _saveData;
    }
    // 获取已转发操作的统计数据
    async getTransmitChartUseData() {
        const params = {
            startTime: this.state.startTime,
            endTime: this.state.endTime,
            event: 4,
            eventType: 1,
        };
        const data = await this.getChartUseData(params);
        
        // console.log('output->', this.handleChartUseData(data));
        this.setState({
            transmitTableData: this.handleChartUseData(data),
            transmitColumns: this.getClickTableColumns(data)
        });
    }
    // 获取点击使用说明的统计数据
    async getClickHelpData() {
        try {
            const { code, data } = await GetClickHelpPU();
            if (code == 200) {
                console.log('output->', this.handleChartUseData([data]));
                const _data = [
                    {
                        ...data,
                        name: '点击使用说明',
                        configId: null,
                    },
                ];
                this.setState({
                    clickHelpTableData: this.handleChartUseData(_data),
                    clickHelpColumns: this.getClickTableColumns(_data)
                });
            }
        } catch (error) {
            console.log('error', error);
        }
    }
    // 导出用户操作记录数据
    async exportStatsData(event: number, eventType: number, fileName: string = '导出用户数据') {
        const { startTime, endTime } = this.state;
        const params = {
            startTime,
            endTime,
            event,
            eventType,
        };
        const res = await ExportStatsData(params);
        fileName = `${startTime}~${endTime}${fileName}`;
        downloadFile(res, fileName, '.xlsx');
    }
    // 导出用户点击使用说明统计
    async exportHelpData() {
        const res = await DownClickHelpPU();
        downloadFile(res, '用户点击使用说明统计', '.xlsx');
    }

    handleDateSelect = (date: any, dateString: any) => {
        this.setState({
            startTime: dateString[0],
            endTime: dateString[1],
        });
    };

    searchAllByDate() {
        this.getTransmitChartUseData();
        this.getClickHelpData();
    }

    setSelectionType = (selectedRowKeys: any, selectedRows: any) => {
        let list: { label: any; value: any }[] = [];
        let _selectedList: any[] = [];
        selectedRows.forEach((item: { nickName: any; userId: any }) => {
            list.push({
                label: item.nickName,
                value: item.userId,
            });
            _selectedList.push(item.userId);
        });
        this.setState({
            selectOptions: list,
            selectedList: _selectedList,
            selectedRows: selectedRowKeys,
        });
    };
    async componentDidMount() {
        this.searchAllByDate();
    }
    handleOk = () => {};

    render() {
        return (
            <div className="gutter-example button-demo">
                {/* <BreadcrumbCustom /> */}
                <Row gutter={16}>
                    <Col className="gutter-row" md={24}>
                        <div className="gutter-box">
                            <Card
                                title="用户已转发统计(2024-09-20 ~ 目前)"
                                extra={
                                    <Button
                                        type="primary"
                                        onClick={() =>
                                            this.exportStatsData(4, 1, '用户已转发统计数据')
                                        }
                                    >
                                        导出
                                    </Button>
                                }
                                bordered={false}
                            >
                                <Table
                                    tableLayout="auto"
                                    pagination={false}
                                    columns={this.state.transmitColumns}
                                    dataSource={this.state.transmitTableData}
                                />
                            </Card>
                        </div>
                    </Col>
                    <Col className="gutter-row" md={24}>
                        <div className="gutter-box">
                            <Card
                                title="用户点击使用说明统计(2024-09-20 ~ 目前)"
                                extra={
                                    <Button type="primary" onClick={() => this.exportHelpData()}>
                                        导出
                                    </Button>
                                }
                                bordered={false}
                            >
                                <Table
                                    tableLayout="auto"
                                    pagination={false}
                                    columns={this.state.clickHelpColumns}
                                    dataSource={this.state.clickHelpTableData}
                                />
                            </Card>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default UserOperation;
