import React, { useState } from 'react';
import BreadcrumbCustom from '../widget/BreadcrumbCustom';
import { Row, Col, Card, Table, Button, Modal, message, Input, Select } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import value from './themeTest';

const { TextArea } = Input;

const data = [
    {
        key: '1',
        name: 'SCI002',
        colorValue: value,
        type: 'SCI',
        sort: 1,
    },
    {
        key: '2',
        name: 'nature1',
        colorValue: value,
        type: '学术',
        sort: 2,
    },
    {
        key: '3',
        name: 'custom',
        colorValue: value,
        type: '商用',
        sort: 3,
    },
];

const themeTypeOption = [
    {
        value: 1,
        label: 'SCI',
    },
    {
        value: 2,
        label: '学术',
    },
    {
        value: 3,
        label: '商用',
    },
];

const ThemeManage = () => {
    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            render: (text: any) => <span>{text}</span>,
        },
        {
            title: '主要配色',
            dataIndex: 'colorValue',
            key: 'colorValue',
            render: (value: any) => (
                <div className="theme-color" style={{ width: value.color.length * 26 + 'px' }}>
                    {value.color.map((item: any) => {
                        return (
                            <span
                                key={item.color}
                                className="theme-color-item"
                                style={{ backgroundColor: item }}
                            ></span>
                        );
                    })}
                </div>
            ),
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: '排序',
            dataIndex: 'sort',
            key: 'sort',
        },
        {
            title: '操作',
            key: 'action',
            dataIndex: 'action',
            render: (item: any, record: any) => (
                <span>
                    <Button onClick={() => onClickEditDialog(record)}>编辑</Button>
                    <span className="ant-divider" />
                    <Button type="primary" danger>
                        删除
                    </Button>
                    <span className="ant-divider" />
                </span>
            ),
        },
    ];
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [themeItem, setThemeItem] = useState({ name: '', type: null, colorValue: '' });
    const [addDialogTitle, setAddDialogTitle] = useState('新增主题配色方案');

    const checkboxChage = (newSelectedRowKeys: any, newSelectedRows: any) => {
        setSelectedRowKeys(newSelectedRowKeys);
        console.log(newSelectedRows[0]);
    };

    const submit = () => {
        setIsModalOpen(false);
    };

    const onClickAddDialog = () => {
        setAddDialogTitle('新增主题配色方案');
        setIsModalOpen(true);
    };
    const onClickEditDialog = (item: any) => {
        console.log(item);
        const { name, type, colorValue } = item;
        console.log(name);
        setThemeItem({ name, type, colorValue });
        console.log(themeItem);
        setAddDialogTitle('编辑主题配色方案');
        setIsModalOpen(true);
    };

    return (
        <div>
            <BreadcrumbCustom breads={['主题管理']} />
            <Row gutter={16}>
                <Col md={24}>
                    <Card title="主题管理" bordered={false}>
                        <div className="theme-top-con">
                            <Button type="primary" onClick={onClickAddDialog}>
                                新增
                            </Button>
                            <Button type="primary" danger>
                                删除
                            </Button>
                        </div>
                        <Table
                            columns={columns}
                            dataSource={data}
                            rowSelection={{
                                type: 'checkbox',
                                onChange: checkboxChage,
                                selectedRowKeys,
                            }}
                        />
                    </Card>
                </Col>
            </Row>
            <Modal
                style={{ marginTop: '2vh' }}
                title={addDialogTitle}
                className="add-dialog"
                visible={isModalOpen}
                onOk={submit}
                onCancel={() => setIsModalOpen(false)}
                footer={[
                    <div key="footer">
                        <Button key="back" onClick={() => setIsModalOpen(false)}>
                            取消
                        </Button>
                        <Button key="submit" type="primary" onClick={submit}>
                            确定
                        </Button>
                    </div>,
                ]}
            >
                <div className="add-dialog-top">
                    <span>名称：</span>
                    <Input
                        allowClear
                        style={{ width: '200px' }}
                        placeholder="请输入主题名称"
                        onChange={(e) => setThemeItem({ ...themeItem, name: e.target.value })}
                    />
                    <span style={{ marginLeft: '20px' }}>类型：</span>
                    <Select
                        allowClear
                        style={{ width: '200px' }}
                        placeholder="请选择类型"
                        onChange={(val: any) => setThemeItem({ ...themeItem, type: val })}
                        options={themeTypeOption}
                    />
                </div>
                <div className="add-dialog-con">
                    <TextArea rows={24} />
                </div>
            </Modal>
        </div>
    );
};

export default ThemeManage;
