import React from 'react'

function BaiduAnalysis() {
  return (
      <div style={{width: '100%', height: '100%', marginTop: '20px'}}>
          <iframe src="https://tongji.baidu.com/" width="100%" height="100%"></iframe>
      </div>
  )
}

export default BaiduAnalysis