import httpAjax from '../utils/httpAjax';

/**
 * 获取意见列表
 */
export const GetRequireList = (parmas: any) => {
    return httpAjax({
        url: `/sysUserOpinion/queryUserOpinion`,
        method: 'post',
        data: parmas,
    });
};
/**
 * 获取意见列表
 */
export const SelectIsUse = (parmas: any) => {
    return httpAjax({
        url: `/sysUserOpinion/update?id=${parmas.id}&status=${parmas.status}&userId=${parmas.userId}`,
        method: 'post',
    });
};
