export interface IFMenuBase {
    key: string;
    title: string;
    icon?: string;
    component?: string;
    query?: string;
    requireAuth?: string;
    route?: string;
    /** 是否登录校验，true不进行校验（访客） */
    login?: boolean;
}

export interface IFMenu extends IFMenuBase {
    subs?: IFMenu[];
}

const menus: {
    menus: IFMenu[];
    others: IFMenu[] | [];
    [index: string]: any;
} = {
    menus: [
        // 菜单相关路由
        {
            key: '/app/home',
            title: '首页',
            icon: 'home',
            component: 'Home',
        },
        // {
        //     key: '/app/webManage',
        //     title: '网站管理',
        //     icon: 'user',
        //     subs: [
        //         { key: '/app/webManage/BaiduAnalysis', title: '百度统计', component: 'BaiduAnalysis' },
        //     ]
        // },
        {
            key: '/app/userInfo',
            title: '用户管理',
            icon: 'user',
            subs: [
                { key: '/app/userInfo/index', title: '用户信息统计', component: 'UserInfo' },
                // { key: '/app/userInfo/PVUV', title: '用户PV/UV统计', component: 'PVUV' },
            ],
        },
        {
            key: '/app/dataManage',
            title: '数据管理',
            icon: 'copy',
            subs: [
                // { key: '/app/dataManage/aiQuery', title: 'AI问图', component: 'AiQuery' },
                { key: '/app/dataManage/base', title: '基础制图', component: 'Base' },
                { key: '/app/dataManage/expert', title: '专家图例', component: 'Expert' },
                {
                    key: '/app/dataManage/funOperation',
                    title: '功能运营',
                    component: 'FunOperation',
                    subs: [
                        {
                            key: '/app/dataManage/funOperation/themeOperation',
                            title: '主题运营',
                            component: 'FunOperation',
                        },
                        {
                            key: '/app/dataManage/funOperation/aiColor',
                            title: 'AI问色运营',
                            component: 'AiColor',
                        }
                    ]
                },
                {
                    key: '/app/dataManage/userOperation',
                    title: '用户运营',
                    component: 'UserOperation',
                },
                { key: '/app/dataManage/saveManage', title: '保存管理', component: 'SaveManage' },
            ],
        },
        {
            key: '/app/order',
            title: '订单管理',
            icon: 'theme',
            subs: [
                { key: '/app/order/ConsumeOrder', title: '消费订单', component: 'ConsumeOrder' },
                { key: '/app/order/PointOrder', title: '智点消耗订单', component: 'PointOrder' },
            ],
        },
        {
            key: '/app/requireManage',
            title: '需求管理',
            icon: 'theme',
            subs: [
                {
                    key: '/app/requireManage/RequireManage',
                    title: '需求列表',
                    component: 'RequireManage',
                },
            ],
        },
        // {
        //     key: '/app/themeManage/index',
        //     title: '主题管理',
        //     icon: 'theme',
        //     component: 'ThemeManage',
        // },
        // {
        //     key: '/app/ui',
        //     title: 'UI',
        //     icon: 'scan',
        //     subs: [
        //         { key: '/app/ui/banners', title: '轮播图', component: 'Banners' },
        //         { key: '/app/ui/wysiwyg', title: '富文本', component: 'WysiwygBundle' },
        //         { key: '/app/ui/drags', title: '拖拽', component: 'Drags' },
        //     ],
        // },
        // {
        //     key: '/app/table',
        //     title: '表格',
        //     icon: 'copy',
        //     subs: [
        //         { key: '/app/table/basicTable', title: '基础表格', component: 'BasicTable' },
        //         { key: '/app/table/advancedTable', title: '高级表格', component: 'AdvancedTable' },
        //         {
        //             key: '/app/table/asynchronousTable',
        //             title: '异步表格',
        //             component: 'AsynchronousTable',
        //         },
        //     ],
        // },
        // {
        //     key: '/subs4',
        //     title: '页面',
        //     icon: 'switcher',
        //     subs: [
        //         { key: '/login', title: '登录' },
        //         { key: '/404', title: '404' },
        //     ],
        // },
        // {
        //     key: '/app/auth',
        //     title: '权限管理',
        //     icon: 'safety',
        //     subs: [
        //         { key: '/app/auth/basic', title: '基础演示', component: 'AuthBasic' },
        //         {
        //             key: '/app/auth/routerEnter',
        //             title: '路由拦截',
        //             component: 'RouterEnter',
        //             requireAuth: 'auth/testPage',
        //         },
        //     ],
        // },
        // {
        //     key: '/app/extension',
        //     title: '功能扩展',
        //     icon: 'bars',
        //     subs: [
        //         {
        //             key: '/app/extension/multiple',
        //             title: '多级菜单',
        //             subs: [
        //                 {
        //                     key: '/app/extension/multiple/child',
        //                     title: '多级菜单子菜单',
        //                     subs: [
        //                         {
        //                             key: '/app/extension/multiple/child/child',
        //                             title: '多级菜单子子菜单',
        //                             component: 'MultipleMenu',
        //                         },
        //                     ],
        //                 },
        //             ],
        //         },
        //         {
        //             key: '/app/extension/env',
        //             title: '环境配置',
        //             component: 'Env',
        //         },
        //     ],
        // },
    ],
    others: [], // 非菜单相关路由
};

export default menus;
