import httpAjax from '../utils/httpAjax';

/**
 * 获取每日登录用户数（DAU）\(MAU)根据记录表
 */
export const GetUserLoginInfos = () => {
    return httpAjax({
        url: `/system/user/queryDailyAndMonthlyActiveUserCoinLog`,
        method: 'get',
    });
};
