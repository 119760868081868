/**
 * 用户数据统计
 */
import React, { useState } from 'react';
import { Row, Col, Card, Timeline, Table, Button, DatePicker } from 'antd';
import BreadcrumbCustom from '../widget/BreadcrumbCustom';
import { GetChartUseData, ExportStatsData, GetChartClickData, ExportStatsClickData } from '../../api/stats';
import { GetExpertSaveExportPU, DownExpertSaveExportPU } from '../../api/data';
import { downloadFile } from '../../utils/common';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';

const { RangePicker } = DatePicker;

class Expert extends React.Component {
    state = {
        selectOptions: [
            {
                label: '',
                value: '',
            },
        ],
        columns: [],
        saveTableData: [],
        exportTableData: [],
        transmitTableData: [],
        userTableData: [],
        clickTableData: [],
        startTime: '',
        endTime: '',
        totalUser: 0,
        newUser: 0,
        visible: false,
        setIsModalOpen: false,
        selectedList: [],
        selectedRows: [],
        expirationTime: moment().add(1, 'days').format('YYYY-MM-DD HH:MM:SS'),
    };
    // 获取图表使用统计数据
    // async getChartUseData() {
    //     const { code, data } = await GetExpertSaveExportPU();
    //     if (code === 200) {
    //         return data;
    //     }
    // }
    // 获取表格表头数据(保存/导出)
    getTableColumns(data: any) {
        const newColumns: any = [
            {
                title: '项目名字',
                dataIndex: 'type',
                fixed: 'left',
                width: 70,
            },
        ];
        data.forEach((item: { chartName: string; configsUserId: string }) => {
            const columnsItem = {
                title: item.chartName,
                dataIndex: `chart${item.configsUserId}`,
                width: 180,
            };
            newColumns.push(columnsItem);
        });
        this.setState({
            columns: newColumns,
        });
    }
    // 处理PVUV数据，type:0为保存，1为导出
    handleChartUseData(data: any, type = 0) {
        let PV = {
            type: 'PV',
        };
        let UV = {
            type: 'UV',
        };
        data.forEach(
            (item: { configsUserId: any; save: any; saveUv: any; export: any; exportUv: any }) => {
                (PV as any)[`chart${item.configsUserId}`] = type == 0 ? item.save : item.export;
                (UV as any)[`chart${item.configsUserId}`] = type == 0 ? item.saveUv : item.exportUv;
            }
        );
        let _saveData = [PV, UV];
        return _saveData;
    }

    handleChartClickData(data: any) {
        let PV = {
            type: 'PV',
        };
        let UV = {
            type: 'UV',
        };
        data.forEach((item: { configId: any; pv: any; uv: any }) => {
            (PV as any)[`chart${item.configId}`] = item.pv;
            (UV as any)[`chart${item.configId}`] = item.uv;
        });
        let _saveData = [PV, UV];
        return _saveData;
    }

    // 获取图表点击统计数据
    async getChartClickData() {
        const params = {
            startTime: this.state.startTime,
            endTime: this.state.endTime,
            type: 2,
        };
        const { data } = await GetChartClickData(params);
        // this.getTableColumns(data);
        this.setState({
            clickTableData: this.handleChartClickData(data),
        });
    }

    // 获取专家图例保存操作的统计数据
    async getExpertSaveExportPU() {
        try {
            const { startTime, endTime } = this.state;
            const params = {
                startTime,
                endTime,
            };
            const { code, data } = await GetExpertSaveExportPU(params);
            if (code == 200) {
                console.log('output->', data);
                this.getTableColumns(data);
                this.setState({
                    saveTableData: this.handleChartUseData(data),
                    exportTableData: this.handleChartUseData(data, 1),
                });
            }
        } catch (error) {
            console.log('output->', error);
        }
    }

    // 导出数据
    async exportStatsData() {
        const { startTime, endTime } = this.state;
        const params = {
            startTime,
            endTime,
        };
        const res = await DownExpertSaveExportPU(params);
        let fileName: any = `${startTime}~${endTime}专家图例保存/导出统计`;
        downloadFile(res, fileName, '.xlsx');
    }
    // 导出图表点击统计数据
    async exportClickStatsData(fileName: string = '用户点击专家图例统计数据') {
        const { startTime, endTime } = this.state;
        const params = {
            startTime,
            endTime,
            type: 2
        };
        const res = await ExportStatsClickData(params);
        fileName = `${startTime}~${endTime}${fileName}`;
        downloadFile(res, fileName, '.xlsx');
    }

    handleDateSelect = (date: any, dateString: any) => {
        // console.log(dateString);
        this.setState({
            startTime: dateString[0],
            endTime: dateString[1],
        });
    };

    searchAllByDate() {
        this.getExpertSaveExportPU();
        this.getChartClickData();
    }

    setSelectionType = (selectedRowKeys: any, selectedRows: any) => {
        console.log(selectedRowKeys);
        console.log(selectedRows);
        let list: { label: any; value: any }[] = [];
        let _selectedList: any[] = [];
        selectedRows.forEach((item: { nickName: any; userId: any }) => {
            list.push({
                label: item.nickName,
                value: item.userId,
            });
            _selectedList.push(item.userId);
        });
        this.setState({
            selectOptions: list,
            selectedList: _selectedList,
            selectedRows: selectedRowKeys,
        });
    };
    async componentDidMount() {
        this.searchAllByDate();
    }
    handleOk = () => { };
    // handleSelectChange = () => {

    // }

    render() {
        return (
            <div className="gutter-example button-demo">
                {/* <BreadcrumbCustom /> */}
                <Row gutter={16}>
                    <Col className="gutter-row" md={24}>
                        <Card>
                            <div style={{ position: 'relative' }}>
                                <span style={{ marginRight: '10px' }}>日期：</span>
                                <RangePicker
                                    onChange={this.handleDateSelect}
                                    locale={locale}
                                    ranges={{
                                        今天: [moment(), moment()],
                                        本月: [moment().startOf('month'), moment().endOf('month')],
                                        本周: [moment().startOf('week'), moment().endOf('week')],
                                    }}
                                />
                                <Button
                                    style={{ marginLeft: '20px' }}
                                    onClick={() => this.searchAllByDate()}
                                >
                                    查询
                                </Button>
                                {/* <Button
                                    style={{ position: 'absolute', right: '0px' }}
                                    type="primary"
                                    onClick={() => this.exportStatsData()}
                                >
                                    导出
                                </Button> */}
                            </div>
                        </Card>
                    </Col>
                    <Col className="gutter-row" md={24}>
                        <div className="gutter-box">
                            <Card
                                title="专家图例点击统计"
                                extra={
                                    <Button
                                        type="primary"
                                        onClick={() =>
                                            this.exportClickStatsData()
                                        }
                                    >
                                        导出
                                    </Button>
                                }
                                bordered={false}
                            >
                                <Table
                                    tableLayout="auto"
                                    pagination={false}
                                    scroll={{ x: 1200 }}
                                    columns={this.state.columns}
                                    dataSource={this.state.clickTableData}
                                />
                            </Card>
                        </div>
                    </Col>
                    <Col className="gutter-row" md={24}>
                        <div className="gutter-box">
                            <Card
                                title="专家图例保存统计"
                                extra={
                                  <Button
                                    type="primary"
                                    onClick={() =>
                                      this.exportStatsData()
                                    }
                                  >
                                    导出
                                  </Button>
                                }
                                bordered={false}
                            >
                                <Table
                                    tableLayout="auto"
                                    pagination={false}
                                    scroll={{ x: 1200 }}
                                    columns={this.state.columns}
                                    dataSource={this.state.saveTableData}
                                />
                            </Card>
                        </div>
                    </Col>
                    <Col className="gutter-row" md={24}>
                        <div className="gutter-box">
                            <Card
                                title="专家图例导出统计"
                                extra={
                                  <Button
                                    type="primary"
                                    onClick={() =>
                                      this.exportStatsData()
                                    }
                                  >
                                    导出
                                  </Button>
                                }
                                bordered={false}
                            >
                                <Table
                                    tableLayout="auto"
                                    pagination={false}
                                    scroll={{ x: 1200 }}
                                    columns={this.state.columns}
                                    dataSource={this.state.exportTableData}
                                />
                            </Card>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Expert;
