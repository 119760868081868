import httpAjax from '../utils/httpAjax';

/**
 * 获取专家图例保存 导出 查询PV PU
 */
export const GetExpertSaveExportPU = (params: any) => {
    return httpAjax({
        url: `/user/coinLog/queryExpertSaveExportPU`,
        method: 'post',
        data: params,
    });
};
/**
 * 导出专家图例保存 导出 查询PV PU
 */
export const DownExpertSaveExportPU = (params: any) => {
    return httpAjax({
        url: `/user/coinLog/queryExpertSaveExportExcel`,
        method: 'post',
        responseType: 'blob', //必须要配置blob
        data: params,
    });
};
/**
 * 获取主题保存 导出 查询PV PU
 */
export const GetThemeSaveExportPU = () => {
    return httpAjax({
        url: `/chart/theme/querySaveExportPv`,
        method: 'get',
    });
};
/**
 * 导出主题保存 导出 查询PV PU
 */
export const DownThemeSaveExportPU = () => {
    return httpAjax({
        url: `/chart/theme/exportChartThemes`,
        method: 'get',
        responseType: 'blob', //必须要配置blob
    });
};

/**
 * 获取点击使用说明的PV PU
 */
export const GetClickHelpPU = () => {
    return httpAjax({
        url: `/user/coinLog/operationPvUvTotal`,
        method: 'get',
    });
};
/**
 * 导出点击使用说明的PV PU
 */
export const DownClickHelpPU = () => {
    return httpAjax({
        url: `/user/coinLog/exportActivitySummaryExcel`,
        method: 'get',
        responseType: 'blob', //必须要配置blob
    });
};
/**
 * 获取保存数据
 */
export const GetSavenInfos = (parmas: any) => {
    return httpAjax({
        url: `/statistics/query/chartConfigCount`,
        method: 'post',
        data: parmas,
    });
};
/**
 * 导出保存数据
 */
export const DownSavenInfos = (params: any) => {
    return httpAjax({
        url: `/statistics/export/chartConfigCountExport`,
        method: 'post',
        responseType: 'blob', //必须要配置blob
        data: params,
    });
};
/**
 * 获取用户保存图片列表
 */
export const GetUserSaveImgList = (parmas: any) => {
    return httpAjax({
        // url: `/statistics/queryPhotosByUserId`,
        url: `/statistics/queryPhotosByUserId?userId=${parmas.userId}`,
        method: 'get',
    });
};
/**
 * 获取用户保存图片列表
 */
export const GetUserExportImgList = (parmas: any) => {
    return httpAjax({
        url: `/userExport/queryUserExportList?userId=${parmas.userId}`,
        method: 'get',
    });
};
