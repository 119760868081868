import axios from 'axios';
import { message } from 'antd';
import umbrella from 'umbrella-storage';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { tansParams } from '../utils/common';

/**
 * ajax地址
 */
const baseURL = process.env.REACT_APP_URL;
// const devURL = process.env.REACT_APP_URL;
// const baseURL = process.env.NODE_ENV === "production" ? proURL : devURL;
const HTTPAJAX = axios.create({
    baseURL,
    timeout: 100000,
});

const requestInterceptor = (config) => {
    // 是否需要设置 token
    const tokenKey = umbrella.getLocalStorage('tokenKey');
    const isToken = (config.headers || {}).isToken === false;

    if (tokenKey && !isToken) {
        config.headers['Authorization'] = 'Bearer ' + tokenKey; //请求是否需要携带自定义Token
    }
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
        let url = config.url + '?' + tansParams(config.params);
        url = url.slice(0, -1);
        config.params = {};
        config.url = url;
    }
    return config;
};

// 响应拦截器
const responseInterceptor = (res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = res.data.msg;
    if (code === 401) {
        message.error('登录状态已过期，请重新登录');
        return <Redirect to={'/login'} />;
        // MessageBox.confirm(
        //   "登录状态已过期，您可以继续留在该页面，或者重新登录",
        //   "系统提示",
        //   {
        //     confirmButtonText: "重新登录",
        //     cancelButtonText: "取消",
        //     type: "warning",
        //   }
        // )
        //   .then(() => {
        //     store.dispatch("LogOut").then(() => {
        //       location.href = "/index";
        //     });
        //   })
        //   .catch(() => {});
    } else if (code === 500) {
        message.error(msg || '请求失败，请稍后重试！');
        // return Promise.reject(new Error(msg));
        // return res.data;
        return res.data;
    } else if (code === 404) {
        // Message.warning(msg);
        return res.data;
    } else if (code === 200) {
        return res.data;
    } else {
        // Notification.error({
        //   title: msg,
        // });
        message.error(msg || '请求失败，请稍后重试！');
        return Promise.reject('error');
    }
};
const errorInterceptor = (error) => {
    console.log('output->', error);
    let { message } = error;
    if (message == 'Network Error') {
        message = '后端接口连接异常';
    } else if (message.includes('timeout')) {
        message = '系统接口请求超时';
    } else if (message.includes('Request failed with status code')) {
        message = '系统接口' + message.substr(message.length - 3) + '异常';
    }
    // message.error(message);
    return Promise.reject(error);
};

// request请求发起拦截器
HTTPAJAX.interceptors.request.use(requestInterceptor, errorInterceptor);
// request请求响应拦截器
HTTPAJAX.interceptors.response.use(responseInterceptor, errorInterceptor);
export default HTTPAJAX;
