import React, { useState, useEffect } from 'react';
import BreadcrumbCustom from '../widget/BreadcrumbCustom';
import { Row, Col, Card, Table, Button, Modal, message, Input, Select, Image } from 'antd';
import { GetRequireList, SelectIsUse } from '../../api/require';

const RequireManage = () => {
    const columns = [
        {
            title: '手机号',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            width: 180,
        },
        {
            title: '上传时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 180,
        },
        {
            title: '内容',
            dataIndex: 'opinion',
            key: 'opinion',
            width: 500,
            // render: (text: any, record: any) => <a onClick={() =>showFileListModal(record)}>{text}</a>,
        },
        {
            title: '图片',
            dataIndex: 'imageUrl',
            key: 'imageUrl',
            render: (list: any, record: any) => (
                //  <div>{JSON.parse(record.imageUrl).map((item: any) => { return <Image width={200} src={item} /> })}</div>
                <div>
                    <Image width={200} src={record.imageUrl} />
                    {/* {record.imgNum > 1 && <Button>查看更多</Button>} */}
                </div>
            ),
        },
        {
            title: '状态',
            dataIndex: 'statusInfo',
            key: 'statusInfo',
            width: 180,
            render: (item: any, record: any) => (
                <span
                    style={{
                        color:
                            record.status == 1
                                ? '#32CD32'
                                : record.status == 2
                                ? '#CD5C5C'
                                : '#000',
                    }}
                >
                    {item}
                </span>
            ),
        },
        {
            title: '操作',
            key: 'action',
            dataIndex: 'action',
            render: (item: any, record: any) => (
                <span>
                    <Button
                        disabled={record.status !== 0}
                        onClick={() => selectIsUse(record, 1)}
                        type="primary"
                    >
                        采纳
                    </Button>
                    <span className="ant-divider" />
                    <Button
                        disabled={record.status !== 0}
                        onClick={() => selectIsUse(record, 2)}
                        type="primary"
                        danger
                    >
                        不采纳
                    </Button>
                    <span className="ant-divider" />
                </span>
            ),
        },
    ];
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [tableData, setTableData] = useState<any>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    useEffect(() => {
        getRequireList();
    }, []);
    const getRequireList = async () => {
        try {
            const parmas = {
                // startTime: "",
                // endTime: "",
                phoneNumber,
                // source: "",
                // knowChannel: "",
            };
            const { code, data } = await GetRequireList(parmas);
            if (code == 200) {
                console.log('output->', data);
                // const _data = handleData(data)
                // const _data = await handleData(data)
                handleData(data);
                // setTableData(_data)
            }
        } catch (error) {
            console.log('output->', error);
        }
    };
    const baseURL = process.env.REACT_APP_URL;
    const handleData = (data: any) => {
        data.forEach((item: any) => {
            if (item.imageUrl) {
                let splice = item.imageUrl.split(',');
                console.log('output->splice', splice);
                item.imgNum = splice.length;
                item.imageUrl = `https://www.topcharts.cn${splice[0]}`;
                // item.imageUrl = JSON.stringify(splice)
            }
        });
        console.log('output->', data);
        setTableData(data);
        // return new Promise<any>(resolve => {
        //   resolve(data)
        // })
    };
    const checkboxChage = (newSelectedRowKeys: any, newSelectedRows: any) => {
        setSelectedRowKeys(newSelectedRowKeys);
        console.log(newSelectedRows[0]);
    };
    const showFileListModal = (record: any) => {
        setIsModalOpen(true);
    };
    const closeFileListModal = () => {
        setIsModalOpen(false);
    };
    const selectIsUse = async (record: any, type: number) => {
        try {
            const parmas = {
                status: type,
                id: record.id,
                userId: record.userId,
            };
            const { code, data } = await SelectIsUse(parmas);
            if (code == 200) {
                message.success('修改成功');
                getRequireList();
                console.log('data', data);
            }
        } catch (error) {
            console.log('error', error);
        }
    };
    return (
        <div className="gutter-example button-demo">
            {/* <BreadcrumbCustom breads={['主题管理']} /> */}
            <Row gutter={16}>
                <Col md={24}>
                    <Card title="保存管理" bordered={false}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '10px',
                            }}
                        >
                            {/* <div>
                                            <span style={{ marginRight: '10px' }}>日期：</span>
                                            <RangePicker
                                                onChange={this.handleDateSelect}
                                                locale={locale}
                                                ranges={{
                                                    今天: [moment(), moment()],
                                                    本月: [
                                                        moment().startOf('month'),
                                                        moment().endOf('month'),
                                                    ],
                                                    本周: [
                                                        moment().startOf('week'),
                                                        moment().endOf('week'),
                                                    ],
                                                }}
                                            />
                                        </div> */}
                            <span style={{ marginLeft: '20px' }}>手机号：</span>
                            <Input
                                allowClear
                                style={{ width: '180px' }}
                                placeholder="请输入手机号"
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                            <Button style={{ marginLeft: '20px' }} onClick={() => getRequireList()}>
                                查询
                            </Button>
                        </div>
                        <Table columns={columns} dataSource={tableData} />
                    </Card>
                </Col>
            </Row>
            <Modal
                style={{ marginTop: '20vh' }}
                title="保存文件列表"
                visible={isModalOpen}
                onCancel={() => closeFileListModal()}
            >
                <div className="gutter-example button-demo">22</div>
            </Modal>
        </div>
    );
};

export default RequireManage;
