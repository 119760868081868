const value = {
    color: ['#6b00ff', '#fc00b9', '#ff0075', '#ff7543', '#ffbf39', '#f9f871'],
    backgroundColor: 'rgba(255,255,255,1)',
    textStyle: {},
    title: {
        textStyle: {
            color: '#111111',
        },
        subtextStyle: {
            color: '#999999',
        },
    },
    line: {
        itemStyle: {
            borderWidth: 2,
        },
        lineStyle: {
            width: 3,
        },
        symbolSize: 8,
        symbol: 'emptyRect',
        smooth: false,
    },
    radar: {
        itemStyle: {
            borderWidth: '2',
        },
        lineStyle: {
            width: '3',
        },
        symbolSize: '8',
        symbol: 'emptyRect',
        smooth: false,
    },
    bar: {
        itemStyle: {
            barBorderWidth: '0',
            barBorderColor: '#ccc',
        },
    },
    pie: {
        itemStyle: {
            borderWidth: '0',
            borderColor: '#ccc',
        },
    },
    scatter: {
        itemStyle: {
            borderWidth: '0',
            borderColor: '#ccc',
        },
    },
    boxplot: {
        itemStyle: {
            borderWidth: '0',
        },
    },
    parallel: {
        itemStyle: {
            borderWidth: '0',
            borderColor: '#ccc',
        },
    },
    sankey: {
        itemStyle: {
            borderWidth: '0',
            borderColor: '#ccc',
        },
    },
    funnel: {
        itemStyle: {
            borderWidth: '0',
            borderColor: '#ccc',
        },
    },
    gauge: {
        itemStyle: {
            borderWidth: '0',
            borderColor: '#ccc',
        },
    },
    candlestick: {
        itemStyle: {
            color: '#ff0075',
            color0: '#6b00ff',
            borderColor: '#f9f871',
            borderColor0: '#f9f871',
            borderWidth: '1',
        },
    },
    graph: {
        itemStyle: {
            borderWidth: '0',
            borderColor: '#ccc',
        },
        lineStyle: {
            width: '1',
            color: '#ff0075',
        },
        symbolSize: '8',
        symbol: 'emptyRect',
        smooth: false,
        color: ['#6b00ff', '#fc00b9', '#ff0075', '#ff7543', '#ffbf39', '#f9f871'],
        label: {
            color: '#ffffff',
        },
    },
    map: {
        itemStyle: {
            areaColor: '#eeeeee',
            borderColor: '#999999',
            borderWidth: 0.5,
        },
        label: {
            color: '#28544e',
        },
        emphasis: {
            itemStyle: {
                areaColor: 'rgba(34,195,170,0.25)',
                borderColor: '#22c3aa',
                borderWidth: 1,
            },
            label: {
                color: '#349e8e',
            },
        },
    },
    geo: {
        itemStyle: {
            areaColor: '#eeeeee',
            borderColor: '#999999',
            borderWidth: 0.5,
        },
        label: {
            color: '#28544e',
        },
        emphasis: {
            itemStyle: {
                areaColor: 'rgba(34,195,170,0.25)',
                borderColor: '#22c3aa',
                borderWidth: 1,
            },
            label: {
                color: '#349e8e',
            },
        },
    },
    categoryAxis: {
        axisLine: {
            show: true,
            lineStyle: {
                color: '#cccccc',
            },
        },
        axisTick: {
            show: false,
            lineStyle: {
                color: '#333',
            },
        },
        axisLabel: {
            show: true,
            color: '#999999',
        },
        splitLine: {
            show: true,
            lineStyle: {
                color: ['#eeeeee'],
            },
        },
        splitArea: {
            show: false,
            areaStyle: {
                color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
            },
        },
    },
    valueAxis: {
        axisLine: {
            show: true,
            lineStyle: {
                color: '#cccccc',
            },
        },
        axisTick: {
            show: false,
            lineStyle: {
                color: '#333',
            },
        },
        axisLabel: {
            show: true,
            color: '#999999',
        },
        splitLine: {
            show: true,
            lineStyle: {
                color: ['#eeeeee'],
            },
        },
        splitArea: {
            show: false,
            areaStyle: {
                color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
            },
        },
    },
    logAxis: {
        axisLine: {
            show: true,
            lineStyle: {
                color: '#cccccc',
            },
        },
        axisTick: {
            show: false,
            lineStyle: {
                color: '#333',
            },
        },
        axisLabel: {
            show: true,
            color: '#999999',
        },
        splitLine: {
            show: true,
            lineStyle: {
                color: ['#eeeeee'],
            },
        },
        splitArea: {
            show: false,
            areaStyle: {
                color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
            },
        },
    },
    timeAxis: {
        axisLine: {
            show: true,
            lineStyle: {
                color: '#cccccc',
            },
        },
        axisTick: {
            show: false,
            lineStyle: {
                color: '#333',
            },
        },
        axisLabel: {
            show: true,
            color: '#999999',
        },
        splitLine: {
            show: true,
            lineStyle: {
                color: ['#eeeeee'],
            },
        },
        splitArea: {
            show: false,
            areaStyle: {
                color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
            },
        },
    },
    toolbox: {
        iconStyle: {
            borderColor: '#999999',
        },
        emphasis: {
            iconStyle: {
                borderColor: '#666666',
            },
        },
    },
    legend: {
        textStyle: {
            color: '#999999',
        },
    },
    tooltip: {
        axisPointer: {
            lineStyle: {
                color: '#cccccc',
                width: 1,
            },
            crossStyle: {
                color: '#cccccc',
                width: 1,
            },
        },
    },
    timeline: {
        lineStyle: {
            color: '#4ea397',
            width: 1,
        },
        itemStyle: {
            color: '#4ea397',
            borderWidth: 1,
        },
        controlStyle: {
            color: '#4ea397',
            borderColor: '#4ea397',
            borderWidth: 0.5,
        },
        checkpointStyle: {
            color: '#4ea397',
            borderColor: '#3cebd2',
        },
        label: {
            color: '#4ea397',
        },
        emphasis: {
            itemStyle: {
                color: '#4ea397',
            },
            controlStyle: {
                color: '#4ea397',
                borderColor: '#4ea397',
                borderWidth: 0.5,
            },
            label: {
                color: '#4ea397',
            },
        },
    },
    visualMap: {
        color: ['#6b00ff', '#fc00b9', '#ff0075', '#ff7543', '#ffbf39', '#f9f871'],
    },
    dataZoom: {
        backgroundColor: 'rgba(255,255,255,0)',
        dataBackgroundColor: 'rgba(222,222,222,1)',
        fillerColor: 'rgba(114,230,212,0.25)',
        handleColor: '#cccccc',
        handleSize: '100%',
        textStyle: {
            color: '#999999',
        },
    },
    markPoint: {
        label: {
            color: '#ffffff',
        },
        emphasis: {
            label: {
                color: '#ffffff',
            },
        },
    },
};

export default value;
