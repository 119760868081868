import httpAjax from '../utils/httpAjax';

/**
 * 登录
 */
export const ToLogin = (data: any) => {
    return httpAjax({
        url: `/login/management`,
        method: 'post',
        data,
    });
};
